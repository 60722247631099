import { customBaseQuery } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

// Define a service using the base core URL and expected endpoints
export const parametersApi = createApi({
  reducerPath: 'parametersApi',
  tagTypes: ['GetImports'],
  baseQuery: async (args, api, extraOptions) =>
    // custom query adds auth headers and error handling
    customBaseQuery(args, api, extraOptions, 'v2/parameters'),
  endpoints: () => ({}),
});

import { AutoneTranslation, i18nAutone } from '@autone/translations';
import {
  AppShell,
  ErrorFallback,
  useAppSettings,
  useGetUserConfigQuery,
} from '@autone/ui';
import { CORE_ADMIN, CORE_STANDARD } from '@autone/utils';
import { ErrorBoundary } from 'react-error-boundary';

import { APP_NAME } from './constant';
import { store } from './redux/store';
import AppRoutes from './routes/app.routes';

function App() {
  const routes = [AppRoutes];

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings('hub');

  // get the user config - this gets data from core/user/me and populates redux store
  useGetUserConfigQuery();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AutoneTranslation namespace={APP_NAME} i18n={i18nAutone}>
        <AppShell
          reduxStore={store}
          routes={routes}
          permissionGroups={[CORE_STANDARD, CORE_ADMIN]}
        />
      </AutoneTranslation>
    </ErrorBoundary>
  );
}

export default App;
